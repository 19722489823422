// Configuration for client side
const durationsConfig = {
  month: 'monthly',
  onetime: 'one-time'
};
const amountsConfig = {
  month: [100, 200, 300, 400, 500],
  onetime: [250, 500, 750, 1000, 1500]
};
const defaultAmount = {
  month: 50,
  onetime: 750
};
const defaultDonation = {
  donationAmount: defaultAmount['month'],
  donationDuration: 'month'
};
const modalDefaultDonation = {
  donationAmount: 50,
  donationDuration: 'month'
};

const onetimeSKUConfig = {
  live: [
    { amount: '1500', id: 'sku_IElisJHup0nojP' },
    { amount: '1000', id: 'sku_IEliodY88lglPk' },
    { amount: '750', id: 'sku_IEli9AXW8DwNtT' },
    { amount: '500', id: 'sku_IElhJxkNh9UgDp' },
    { amount: '250', id: 'sku_IElhQtqLgKZC8y' }
  ],
  development: [
    { amount: '1500', id: 'sku_IElisJHup0nojP' },
    { amount: '1000', id: 'sku_IEliodY88lglPk' },
    { amount: '750', id: 'sku_IEli9AXW8DwNtT' },
    { amount: '500', id: 'sku_IElhJxkNh9UgDp' },
    { amount: '250', id: 'sku_IElhQtqLgKZC8y' }
  ],
  staging: [
    { amount: '1500', id: 'sku_IEPNpHACYJmUwz' },
    { amount: '1000', id: 'sku_IEPMY1OXxnY4WU' },
    { amount: '750', id: 'sku_IEPLOotEqlMOWC' },
    { amount: '500', id: 'sku_IEPKAxxAxfMnUI' },
    { amount: '250', id: 'sku_IEPIgLRzViwq5z' }
  ]
};

// Configuration for server side
const durationKeysConfig = ['month', 'onetime'];
const donationOneTimeConfig = [10000, 2500, 600];
const donationSubscriptionConfig = {
  duration: {
    month: 'Monthly'
  },
  plans: {
    month: [2500, 350, 50]
  }
};

// Shared paypal configuration
// keep the 5 dollars for the modal
const paypalConfigTypes = {
  live: {
    month: {
      50: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      100: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      200: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      300: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      400: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      500: { planId: 'P-3DP64074N7696241EMKXFN7Y' }
    }
  },
  development: {
    month: {
      50: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      100: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      200: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      300: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      400: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      500: { planId: 'P-3DP64074N7696241EMKXFN7Y' }
    }
  },
  staging: {
    month: {
      50: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      100: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      200: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      300: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      400: { planId: 'P-3DP64074N7696241EMKXFN7Y' },
      500: { planId: 'P-3DP64074N7696241EMKXFN7Y' }
    }
  }
};

const paypalConfigurator = (donationAmount, donationDuration, paypalConfig) => {
  if (donationDuration === 'onetime') {
    return { amount: donationAmount, duration: donationDuration, planId: null };
  }
  return {
    amount: donationAmount,
    duration: donationDuration,
    planId: paypalConfig[donationDuration]['' + donationAmount].planId
  };
};

const donationUrls = {
  successUrl: 'https://www.freecodecamp.org/news/thank-you-for-donating/',
  cancelUrl: 'https://freecodecamp.org/donate'
};

const patreonDefaultPledgeAmount = 50;

const aBTestConfig = {
  isTesting: true,
  type: 'badgeProgressModalCopy'
};

module.exports = {
  durationsConfig,
  amountsConfig,
  defaultAmount,
  defaultDonation,
  durationKeysConfig,
  donationOneTimeConfig,
  donationSubscriptionConfig,
  modalDefaultDonation,
  onetimeSKUConfig,
  paypalConfigTypes,
  paypalConfigurator,
  donationUrls,
  patreonDefaultPledgeAmount,
  aBTestConfig
};
