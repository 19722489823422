import { Link } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import notFoundLogo from '../../assets/images/extraise-404.svg';
import { randomQuote } from '../../utils/get-words';
import { Spacer } from '../helpers';

import './404.css';

const FourOhFour = (): JSX.Element => {
  const { t } = useTranslation();
  // TODO: Remove this type coercion when get-words.js is migrated
  const quote = randomQuote() as { quote: string; author: string };
  return (
    <div className='notfound-page-wrapper'>
      <Helmet title={t('404.page-not-found') + '| extraise.com'} />
      <img alt={t('404.not-found')} src={notFoundLogo} />
      <Spacer />
  
    
      
      
      
    </div>
  );
};

export default FourOhFour;
